@import "~@intc/dlux-bootstrap/dist/css/dlux.css";
/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700'); */

.App {
  text-align: center;
}

.App-header {
		background-color: var(--classic-blue);
		color: var(--light);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.spinner {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

*:focus {
  outline: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
